import Link from 'next/link'
import { TYPOGRAPHY_TYPES, Typography } from '@components/Typography'

export const PromoCard = ({ image, title, link }) => {
  return (
    <Link className='promo-card' href='/[categorySlug]' as={link}>
      <div className='promo-card-overlay' />
      <div className='promo-card' style={{ backgroundImage: `url(${image})` }} />
      <div className='promo-card-border' />
      <div className='promo-card-label'>
        <Typography component={TYPOGRAPHY_TYPES.SUBTITLE} color='white'>
          {title}
        </Typography>
      </div>
    </Link>
  )
}
