import { Row, Col } from 'react-bootstrap'
import { useIsMobileLayout } from '@hooks/useIsMobileLayout'
import { PromoCard } from '@components/PromoCard'
import { ContentWrapper } from '@components/ContentWrapper'

const PROMO_CARDS = [
  {
    image:
      'https://images.ctfassets.net/lvuebjjl6cso/2mLwo1xJQrG3vN94C2h4Ye/11472848d58f3bd50a85511d8008a4ce/convert.jpg',
    title: 'JOYERÍA',
    link: 'joyeria'
  },
  {
    image:
      'https://images.ctfassets.net/lvuebjjl6cso/6MmAQeNxLtR4W1Wqhz5Ejm/ba304f248c3c72c4d41a99ef3034de74/convert.jpg',
    title: 'RELOJERÍA',
    link: 'relojeria'
  }
]

export default function Homepage() {
  const isMobileLayout = useIsMobileLayout()

  // prettier-ignore
  function getImageSource(img) {
    return isMobileLayout
      ? `${img}?w=500&h=500&fm=webp&q=100`
      : `${img}?w=900&h=900&fm=webp&q=100`
  }

  return (
    <ContentWrapper>
      <Row className='container-fluid m-0 p-0'>
        <Col
          className={`d-flex ${isMobileLayout ? 'p-0' : 'promo-padding-desktop-layout-right'}`}
          md={6}
          xs={12}
        >
          <PromoCard {...PROMO_CARDS[0]} image={getImageSource(PROMO_CARDS[0].image)} />
        </Col>
        <Col
          className={`d-flex ${isMobileLayout ? 'p-0 mt-4' : 'promo-padding-desktop-layout-left'}`}
          md={6}
          xs={12}
        >
          <PromoCard {...PROMO_CARDS[1]} image={getImageSource(PROMO_CARDS[1].image)} />
        </Col>
      </Row>
    </ContentWrapper>
  )
}
